import config from "@/config"

const theme = config.COLOR_THEME;

const filepathPrefix = `/brand/${theme}`;

export const getNoTextLogoFilePath = () =>{
    switch(theme){
        case 'MG':
            return `${filepathPrefix}/mg-logo-dark.svg`;
        case 'edmesh':
            return `${filepathPrefix}/edMesh-logo-no-text-large.png`;
        default:
            return `${filepathPrefix}/mg-logo-dark.svg`;
    }
}

export const getScreenshareWatermarkLogo = () =>{
    //Note, be careful when adding another themed image for screenshare watermark - the image size will affect watermark appearance
    switch(theme){
        case 'MG':
            return `${filepathPrefix}/mg-logo.svg`;
        case 'edmesh':
            return `${filepathPrefix}/edMesh-logo-no-text.png`;
        default:
            return `${filepathPrefix}/mg-logo.svg`;
    }
}

export const getSidebarMenuLogo = () => {
    switch(theme){
        case 'MG':
            return `${filepathPrefix}/mg-logo.svg`;
        case 'edmesh':
            return `${filepathPrefix}/edMesh-logo.png`;
        default:
            return `${filepathPrefix}/mg-logo.svg`;
    }
}