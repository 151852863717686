export default class RegexUtils {
  //This pattern was created ad-hoc when new Dash came out, was replaced with Regex pattern below taken from the OLD dashboard
  // static URL_PATTERN =
  //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

  // static URL_PATTERN =
  //   // eslint-disable-next-line no-useless-escape
  //   /^(http(s)?:\/\/)?((([-a-zA-Z0-9@%._~#=]{2,256})*([-a-zA-Z0-9@%._~#=]{2,256}\.[a-z]{2,6}))|((?:(?:|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}))(:[1-9][0-9]{1,5})?([-a-zA-Z0-9@%+_.~#\?&\/\\=]*)$/i;
  static URL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^((https|http|chrome|file|ftp|ssh):\/\/)?(\*\.)?(([-a-zA-Z0-9@%._~#=]{2,256}\.[a-z]{2,6})|(:[1-9][0-9]{1,5}))([-a-zA-Z0-9@%+_.~#\?&\/\/=]*)$/i;

  static YOUTUBE_CHANNEL_PATTERN = /(https?:\/\/)?(www\.)?youtube\.com\/(channel|user|c|gaming|@?(\w)+)(\/?\w+)/;

  static YOUTUBE_VIDEO_PATTERN =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

  static YOUTUBE_PATTERN =
    /(https?:\/\/)?(www\.)?youtube\.com\/(channel|user|c|gaming|@?(\w)+)(\/?\w+)|((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/g;

  static YOUTUBE_LINK_PATTERN =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/i;
  // keeping just in case: /https:\/\/(www\.)?youtube\.com\/(channel|user|c|gaming|watch\?|@?\w+)\/?\w+/;

  static VIMEO_PATTERN = /^(http:\/\/|https:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/i;
  // keeping just in case: /https:\/\/(www\.|player\.)?vimeo.com\/(channels\/(\w+)|groups\/(\w+)|(\w+))\/?(\w+)?\/?(\w+)/;
}
