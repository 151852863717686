import { MUTATION_DEVICE_ACTION_SEND_TAB_CONTROL_COMMAND } from "@/app/gql/devices/device_actions_mutations";
import DeviceUtils from "@/app/utils/devices/device_utils";
import UserUtils from "@/app/utils/users/user_utils";
import { BehaviorSubject } from "rxjs";
import { logger } from "@/app/services/logging/Logger";
import { NatsService } from "../nats/nats-service";
import screenShareSocket from "../socket/screen-share-socket";
import { TEST_WEBFILTER_URL } from "@/app/gql/classes/webfilter/class_webfilter_queries";
import { natsSubscriptionHandler } from "../nats/natsSubscriptionHandler";
import { getScreenshareWatermarkLogo } from "@/app/utils/theming/images";

//This will get the path to the favicon of current Classroom product
let themedIconUrl = getScreenshareWatermarkLogo() ?? null;
class TabControlService {
  
  static get themedIconUrl() {
    if(!themedIconUrl)
    {
      this.setThemedIconUrl()
    }
    return themedIconUrl;
  }

  static setThemedIconUrl() {
    themedIconUrl = getScreenshareWatermarkLogo();
  }

  constructor(apolloClient) {
    this.apolloClient = apolloClient;
    
    screenShareSocket.screenShareReceived().subscribe((data) => {
      if (!data) return;

      if (data.payload.module === "tabs") {
        this.handleTabData(data);
      }
    });

    if (this.classId) {
      this.subscribeToClassTabControlNatsChannel(this.classId);
    } else {
      this.unsubscribeFromClassTabControlNatsChannel();
    }
  }

  /**
   * @var classId
   */
  classId = null;

  /**
   * @var tabControlSubscription
   */
  tabControlSubscriptionClosure = null;

  /**
   * @var deviceTabsChanged
   */
  deviceTabsChanged = new BehaviorSubject();
  /**
   * @returns observable
   */
  onDeviceTabsChanged() {
    return this.deviceTabsChanged.asObservable();
  }

  notifyTabsChanged() {
    this.deviceTabsChanged?.next(this.deviceTabs);
  }

  async subscribeToClassTabControlNatsChannel(classId) {
    this.classId = classId;
    const school_id = UserUtils.getCurrentSchoolUser()?.school_id;
    const allDevicesTabControlChannel = `school.${school_id}.class.${classId}.device.*.tab_control`;
    this.tabControlSubscriptionClosure = natsSubscriptionHandler(allDevicesTabControlChannel, (msg, channel) => {
      const device_signature = channel.split(".")[5];
      switch (msg.type) {
        case "initial":
          this.handleNatsInitial({ device_signature: device_signature }, msg.payload);
          break;
        case "delete":
          this.handleNatsDeleteTab({ device_signature: device_signature }, msg.payload);
          break;
        case "active":
          this.handleNatsSwitchTabFocus({ device_signature: device_signature }, msg.payload);
          break;
        case "add":
          this.handleNatsAdd({ device_signature: device_signature }, msg.payload);
          break;
        case "update":
          this.handleNatsUpdateTab({ device_signature: device_signature }, msg.payload);
          break;
        // case "nats_client_reconnected":
        //   handleNatsClientReconnected();
      }
    });
  }

  sanitizeMGIconForThemedIcon(tab) {

    //This checks if the tab is sent from Chrome extension and updates favicon's url to a themed local resource 
    if(tab.favIconUrl === "/images/mg-logo-dark.svg")
    { 
      tab.favIconUrl = TabControlService.themedIconUrl;
    }
    return tab
  }

  async unsubscribeFromClassTabControlNatsChannel() {
    if (this.tabControlSubscriptionClosure && typeof this.tabControlSubscriptionClosure === "function") {
      this.tabControlSubscriptionClosure();
      this.tabControlSubscriptionClosure = null;
    }
  }

  /**
   * @var {any}
   */
  deviceTabs = {};

  /**
   *
   * @param {object} device
   * @param {string} classId
   * @returns
   */
  refreshTabs(device, classId) {
    let device_signature = typeof device === "object" ? device.device_signature : device;
    return screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
      from: screenShareSocket.socket.id ?? "notset",
      to: device_signature,
      class: classId,
      payload: {
        module: "tabs",
        action: "refresh",
        data: "data",
        from: screenShareSocket.socket.id ?? "notset",
        to: device_signature,
      },
    });
  }

  /**
   *
   * @param {objecy} device
   * @param {Tab[]} tabs
   */
  setDeviceTabs(device, tabs) {
    let device_signature = typeof device === "object" ? device.device_signature : device;
    if (!this.deviceTabs[device_signature]) {
      this.deviceTabs[device_signature] = {
        tabs: [],
      };
    }
    let deviceTabs = this.deviceTabs[device_signature];
    deviceTabs.tabs = tabs;
    this.notifyTabsChanged();
  }

  /**
   *
   * @param {object} device
   * @returns
   */
  getDeviceTabs(device) {
    let device_signature = typeof device === "object" ? device.device_signature : device;
    return this.deviceTabs[device_signature];
  }

  getAllDeviceTabs() {
    return this.deviceTabs;
  }

  /**
   *
   * @param {object} device
   * @param {string} classId
   * @param {Tab} tab
   */
  addTab(device, classId, tab, skipFilter = false) {
    //const currentUserResponse = await this.apolloClient.query({ query: GET_CURRENT_USER });

    if (DeviceUtils.usesNatsMessaging(device)) {
      this.addTabViaNats(device, classId, tab, skipFilter);
    } else {
      if (DeviceUtils.getOS(device.os_type_id) === "Android") {
        this.addTabViaApi(device, tab);
      } else {
        this.addTabViaSocket(device, classId, tab);
      }
    }
  }

  /**
   *
   * @param {object} device
   * @param {string} classId
   * @param {Tab} tab
   */
  addTabViaSocket(device, classId, tab) {
    screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
      to: device.device_signature,
      from: screenShareSocket.socket.id,
      class: classId,
      payload: {
        to: device.device_signature,
        from: screenShareSocket.socket.id,
        module: "tabs",
        action: "add",
        data: tab,
      },
    });
  }

  /**
   *
   * @param {object} device
   * @param {string} classId
   * @param {Tab} tab
   */
  addTabViaApi(device, tab) {
    this.apolloClient.mutate({
      mutation: MUTATION_DEVICE_ACTION_SEND_TAB_CONTROL_COMMAND,
      variables: {
        input: {
          //payload: JSON.stringify(payload),
          to: device.device_signature,
          from: screenShareSocket.socket.id,
          deviceUUID: device.device_uuid,
          payload: {
            action: "add",
            data: tab,
          },
        },
      },
    });
  }
  /**
   *
   * @param {Array} devices
   * @param {string} classId
   * @param {Tab} tab
   */

  async checkIfWebfilterWillBlockUrl(url, devices, classId, profileUUID) {
    const deviceSigs = devices?.map((d) => {
      return d.device_signature;
    });

    const response = await this.apolloClient
      .query({
        query: TEST_WEBFILTER_URL,
        variables: {
          profileUUID: profileUUID,
          url: url,
          classId: classId,
          deviceSignatures: deviceSigs,
        },
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }

  async addTabToMultipleDevices(devices, classId, tab) {
    if (tab.url.indexOf("http") < 0) {
      tab.url = "https://" + tab.url;
    }
    devices.forEach((device) => {
      this.addTab(device, classId, tab, true);
    });
  }

  /**
   *
   * @param {object} device
   * @param {string} classId
   * @param {Tab} tab
   */
  focusTab(device, classId, tab) {
    if (DeviceUtils.usesNatsMessaging(device)) {
      this.focusTabViaNats(device, classId, tab);
    } else {
      screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
        to: device.device_signature,
        from: screenShareSocket.socket.id,
        class: classId,
        payload: {
          to: device.device_signature,
          from: screenShareSocket.socket.id,
          module: "tabs",
          action: "focus",
          data: tab,
          id: tab.id,
        },
      });
    }
  }

  /**
   *
   * @param {Array} devices
   * @param {string} classId
   * @param {Tab} tab
   */
  focusTabOnMultipleDevices(deviceTabs, classId) {
    deviceTabs.forEach((deviceTab) => {
      this.focusTab(deviceTab.device, classId, deviceTab.tab);
    });
  }

  /**
   *
   * @param {object} device
   * @param {string} classId
   * @param {Tab} tab
   */
  closeTab(device, classId, tab) {
    if (DeviceUtils.usesNatsMessaging(device)) {
      this.closeTabViaNats(device, classId, tab);
    } else {
      screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
        to: device.device_signature,
        from: screenShareSocket.socket.id,
        class: classId,
        payload: {
          to: device.device_signature,
          from: screenShareSocket.socket.id,
          module: "tabs",
          action: "delete",
          data: tab,
        },
      });
    }
  }

  /**
   *
   * @param {Array} devices
   * @param {string} classId
   * @param {Tab} tab
   */
  closeTabOnMultipleDevices(deviceTabs, classId) {
    deviceTabs.forEach((deviceTab) => {
      deviceTab.tabs.forEach((tab) => {
        this.closeTab(deviceTab.device, classId, tab);
      });
    });
  }

  /**
   *
   * @param {any} payload
   * @returns
   */
  handleTabData(data) {
    let { payload } = data;
    var tabs = this.deviceTabs[data.from];
    switch (payload.action) {
      case "check":
        this.refreshTabs(this.props.device, this.classId);
        return;
      case "inital": // this is for older Android / iOS / Chrome Extension versions that had the spelling error
      case "initial":
        this.setDeviceTabs(data.from, payload.data);
        break;
      case "update":
        if (tabs && tabs.tabs) {
          var existing = tabs.tabs.find((tab) => tab.id == payload.data.id);
          if (existing) {
            var tabIndex = tabs.tabs.indexOf(existing);
            tabs.tabs[tabIndex] = payload.data;
            this.deviceTabs[data.from].tabs = tabs.tabs;
          }
        }
        this.notifyTabsChanged();
        break;
      case "deleted":
        var deleteExisting = tabs?.tabs.find((tab) => tab.id == payload.data.id);
        if (deleteExisting) {
          var deleteTabIndex = tabs.tabs.indexOf(deleteExisting);
          tabs.tabs.splice(deleteTabIndex, 1);
          this.notifyTabsChanged();
        }
        this.refreshTabs(data.from, data.room_id.toLowerCase().replace("classroom_", ""));
        break;
    }
  }

  handleNatsInitial(device, payload) {
    if (!this.deviceTabs[device.device_signature]) {
      this.deviceTabs[device.device_signature] = {
        tabs: [],
      };
    }
    let deviceTabs = this.deviceTabs[device.device_signature];
    deviceTabs.tabs = payload.tabs.map(this.sanitizeMGIconForThemedIcon);
    this.notifyTabsChanged();
  }

  handleNatsAdd(device, payload) {
    const newTab = payload.tab;
    let deviceTabs = this.deviceTabs[device.device_signature];
    const newTabId = payload.tab.id;
    let tabAlreadyAdded = false;

    if (deviceTabs) {
      deviceTabs.tabs.map((tab) => {
        if (tab.id === newTabId) tabAlreadyAdded = true;
      });
      if (!tabAlreadyAdded) {
        deviceTabs.tabs = [...deviceTabs.tabs, this.sanitizeMGIconForThemedIcon(newTab)];
        this.notifyTabsChanged();
      }
    }
  }

  handleNatsSwitchTabFocus(device, payload) {
    const tab = payload.tab;
    const tabId = tab?.id;
    const windowId = payload.activeWindow;

    if (!tabId)
      logger.warn(
        "[tab-control.service.js] Tab Control 'focus' message has malformed. Payload:",
        payload,
        "Intended device:",
        device
      );

    let deviceTabs = this.deviceTabs[device.device_signature];
    let newTabs = deviceTabs?.tabs.map((tab) => {
      if (tab.windowId === windowId) {
        if (tab.id === tabId) {
          return {
            ...tab,
            active: true,
          };
        } else {
          return {
            ...tab,
            active: false,
          };
        }
      } else {
        return tab;
      }
    });

    if (deviceTabs) {
      deviceTabs.tabs = newTabs;
      this.notifyTabsChanged();
    }
  }

  handleNatsUpdateTab(device, payload) {
    const tabId = payload.tab.id;
    const windowId = payload.tab.windowId;

    let deviceTabs = this.deviceTabs[device.device_signature];
    let tabFound = false;

    let newTabs = deviceTabs?.tabs.map((tab) => {
      if (tab.windowId === windowId && tab.id === tabId) {
        tabFound = true;
        return this.sanitizeMGIconForThemedIcon(payload.tab);
      } else {
        return tab;
      }
    });

    if (tabFound) {
      deviceTabs.tabs = newTabs;
      this.notifyTabsChanged();
    } else {
      if (payload?.tab?.title && payload?.tab?.url && payload?.tab?.id)
        //Make sure there is basic info of the tab before adding it if not found for device's current tabs
        this.handleNatsAdd(device, payload);
    }
  }

  handleNatsDeleteTab(device, payload) {
    let deviceTabs = this.deviceTabs[device.device_signature];

    let newDeviceTabs = deviceTabs?.tabs;

    var existing = newDeviceTabs?.find((tab) => tab?.id === payload?.tab?.id);

    if (existing && existing != -1) {
      newDeviceTabs.splice(newDeviceTabs.indexOf(existing), 1);

      deviceTabs.tabs = newDeviceTabs;
      this.notifyTabsChanged();
    }
  }

  addTabViaNats(device, classId, tab, skipFilter) {
    const school_id = UserUtils.getCurrentSchoolUser()?.school_id;
    const deviceTabControlChannel = `school.${school_id}.class.${classId}.device.${device.device_signature}.tab_control`;
    NatsService.getInstance().publish(deviceTabControlChannel, {
      type: "add",
      targets: [device.device_signature],
      payload: {
        url: tab.url,
        sf: skipFilter,
        //url: tab.url.includes("?") ? `${tab.url}&sf=true`: `${tab.url}?sf=true`,
      },
    });
  }

  focusTabViaNats(device, classId, tab) {
    const school_id = UserUtils.getCurrentSchoolUser()?.school_id;
    const deviceTabControlChannel = `school.${school_id}.class.${classId}.device.${device.device_signature}.tab_control`;
    NatsService.getInstance().publish(deviceTabControlChannel, {
      type: "focus",
      targets: [device.device_signature],
      payload: {
        id: tab.id,
      },
    });
  }

  closeTabViaNats(device, classId, tab) {
    const school_id = UserUtils.getCurrentSchoolUser()?.school_id;
    const deviceTabControlChannel = `school.${school_id}.class.${classId}.device.${device.device_signature}.tab_control`;
    NatsService.getInstance().publish(deviceTabControlChannel, {
      type: "delete",
      targets: [device.device_signature],
      payload: {
        id: tab.id,
      },
    });
  }

  refreshNatsTabs(device, classId) {
    const school_id = UserUtils.getCurrentSchoolUser()?.school_id;
    const deviceTabControlChannel = `school.${school_id}.class.${classId}.device.${device.device_signature}.tab_control`;
    NatsService.getInstance().publish(deviceTabControlChannel, {
      type: "refresh",
      targets: [device.device_signature],
      payload: {
        action: "refresh",
      },
    });
  }

  cleanUp() {
    this.unsubscribeFromClassTabControlNatsChannel();
    this.deviceTabs = {};
  }
}

// export default new TabControlService();
export default (apolloClient) => {
  return new TabControlService(apolloClient);
};
