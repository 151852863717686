
      import config from "@/config";

      // const theme = "$COLOR_THEME";
      //Change Favicon based on theme
      var link = document.querySelector("link[rel~='icon']");
      var loadingIconDiv = document.querySelector("link[rel~='icon']");
      var favIcon = `brand/${config.COLOR_THEME}/favicon.ico`;
      var loadingIcon = config.COLOR_THEME === "edmesh" ? `brand/${config.COLOR_THEME}/mg-logo-dark.svg` : `brand/${config.COLOR_THEME}/edMesh-logo-no-text-large.png`;

      const root = document.documentElement;
      root.classList.remove("MG", "edmesh", "null");
      root.classList.add(config.COLOR_THEME);

      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        link.id = "link-favicon";
        document.head.appendChild(link);
      } else {
        link = document.getElementById("link-favicon");
      }
      link.href = favIcon;

      //Change loader icon based on theme

      const logoDiv = document.getElementById("logo");
      
      logoDiv.innerHTML = `<img src="${loadingIcon}" class="pulsate" height="120px" width="120px" />`;
    