import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./style.css";

export const MGInputsOverlay = ({ trigger, children, onOutsideClick, inheritWidthFromTrigger, ...props }) => {
  const box = useRef();
  const overlay = useRef();

  /**
   * @description This function applies the necessary style attributes to the background overlay
   */
  function styleBackgroundOverlay() {
    overlay.current.style.position = "fixed";
    overlay.current.style.top = "0px";
    overlay.current.style.left = "0px";
    overlay.current.style.width = "100%";
    overlay.current.style.height = "100%";
    overlay.current.style.zIndex = "1000";
    overlay.current.style.overflow = "hidden";
  }

  /**
   * @description document.body has an invisible (by default) scrollbar for overflow, this function returns the width of that scrollbar
   * @returns {number}
   */
  // function getScrollbarWidth() {
  //   // Creating invisible container
  //   const outer = document.createElement("div");
  //   outer.style.visibility = "hidden";
  //   outer.style.overflow = "scroll"; // forcing scrollbar to appear
  //   outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  //   document.body.appendChild(outer);

  //   // Creating inner element and placing it in the container
  //   const inner = document.createElement("div");
  //   outer.appendChild(inner);

  //   // Calculating difference between container's full width and the child width
  //   const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  //   // Removing temporary elements from the DOM
  //   outer.parentNode.removeChild(outer);

  //   return scrollbarWidth;
  // }
  /**
   * @description This function gets the distance (in px) from the bottom of an HTML element to the bottom of browser window
   * @returns {number}
   */
  function getDistanceFromTriggerToBottomOfScreen(trigger) {
    var space = window.innerHeight - trigger.current.children[0].getBoundingClientRect().bottom;

    return space;
  }

  /**
   * @description This function determines if {children} should be displayed above or below the trigger - depending on screen space
   * @returns {number}
   */
  function shouldDisplayBelowTrigger() {
    var distanceFromBottom = getDistanceFromTriggerToBottomOfScreen(trigger);

    //Check if there is enough space below the trigger for the content inside the overlay - as it should display below by default
    if (box.current.getBoundingClientRect().height < distanceFromBottom) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    var oldBodyOverflow = document.body.style.overflow;
    var oldBodyPaddingRight = document.body.style.paddingRight;

    document.body.style.overflow = "hidden";
    // document.body.style.paddingRight = getScrollbarWidth() + "px";

    if (trigger) {
      styleBackgroundOverlay();
      //Get bounding rectangle of trigger component
      let triggerDOMRectangle = trigger.current.children[0].getBoundingClientRect();

      box.current.style.left = triggerDOMRectangle.x + "px";

      box.current.style.display = "block";

      if (inheritWidthFromTrigger) {
        box.current.style.width = triggerDOMRectangle.width + "px";
      }

      //Check if there is enough space below the trigger for the content inside the overlay
      if (shouldDisplayBelowTrigger()) {
        //Show below input if enough space
        box.current.style.top = triggerDOMRectangle.y + triggerDOMRectangle.height + "px";
        box.current.className = "--mg-inputs-overlay-container below";
      } else {
        //Show above input if not enough space
        box.current.style.top = triggerDOMRectangle.y - box.current.getBoundingClientRect().height + "px";
        box.current.className = "--mg-inputs-overlay-container above";
      }

      //box has display:none by default to ensure that the box does not display before styling above is applied
    }
    return function cleanup() {
      document.body.style.overflow = oldBodyOverflow;
      document.body.style.paddingRight = oldBodyPaddingRight;
    };
  });

  const onClickHandler = (evt) => {
    if (typeof onOutsideClick === "function") {
      if (evt.target.id === "mg-overlay-id") {
        onOutsideClick(evt);
      }
    }
  };

  return ReactDOM.createPortal(
    <div
      id="mg-overlay-id"
      className="--mg-inputs-overlay-background"
      ref={overlay}
      onClick={onClickHandler}
      {...props}
    >
      <div className={`--mg-inputs-overlay-container`} ref={box} style={{ display: "none" }}>
        {children}
      </div>
    </div>,
    document.body
  );
};

MGInputsOverlay.propTypes = {
  children: PropTypes.any,
  target: PropTypes.element,
  onOutsideClick: PropTypes.func,
  inheritWidthFromTrigger: PropTypes.bool,
};

MGInputsOverlay.defaultProps = {};

export default MGInputsOverlay;
