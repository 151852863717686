import { getNoTextLogoFilePath } from "@/app/utils/theming/images";

export const SiteLoaderComponent = () => {
  const themedImagePath = getNoTextLogoFilePath();
  return (
  <div className="App">
    <div style={{ marginTop: "25%", textAlign: "center" }}>
      {/* <h6>Loading...</h6> */}
      <img src={themedImagePath} height={"80px"} width={"80px"} />
    </div>
  </div>
)};

export default SiteLoaderComponent;
