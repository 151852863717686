import { MGLinkButton } from "@/components/buttons";
import { MGFlexBox } from "@/components/layout";
import { MGHeading1, MGHeading3 } from "@/components/typography";
import { authService } from "@/main";
import { useTranslation } from "react-i18next";
import MGContent from "../app-components/MGContent/MGContent";
import { getNoTextLogoFilePath } from "../utils/theming/images";

export const NotFoundPage = ({ goBackButtonLink }) => {
  const { t } = useTranslation();

  let errorHeading = t("Page not found");
  let errorMsg = t("The link you followed may be broken, or the page may have been removed");

  if (authService.checkAuthToken()) {
    errorHeading = t("Unauthorized");
    errorMsg = t("Your session has timed out, you will be logged out shortly");
    authService.logUserOut();
  }

  const themedImagePath = getNoTextLogoFilePath();

  return (
    <div className="--page-error-container">
      <MGContent>
        <div>
          <div style={{ marginTop: "15%", textAlign: "center" }}>
            <img src={themedImagePath} height="120px" width="120px" />
          </div>
          <br />
          <br />
          <MGFlexBox>
            <MGHeading1>{errorHeading}</MGHeading1>
          </MGFlexBox>
          <br />
          <br />
          <MGFlexBox>
            <MGHeading3>{errorMsg}</MGHeading3>
          </MGFlexBox>
          <br />
          <br />
          <br />
          <MGFlexBox>
            <MGLinkButton to={goBackButtonLink}>{t("Go Back")}</MGLinkButton>
          </MGFlexBox>
        </div>
      </MGContent>
    </div>
  );
};

NotFoundPage.defaultProps = {
  goBackButtonLink: "/",
};
